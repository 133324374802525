

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 0 3rem;
}

.App-header h1 {
  font-size: 2rem;
  font-weight: 200;
  letter-spacing: 1.7rem;
  padding-bottom: 1rem;
  border-bottom: 0.01rem solid white;
  border-bottom: 1px solid rgb(255 255 255 / 50%);

}

.App-link {
  color: #61dafb;
}


