@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@100..700&display=swap);
body {
  margin: 0;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 0 3rem;
}

.App-header h1 {
  font-size: 2rem;
  font-weight: 200;
  letter-spacing: 1.7rem;
  padding-bottom: 1rem;
  border-bottom: 0.01rem solid white;
  border-bottom: 1px solid rgb(255 255 255 / 50%);

}

.App-link {
  color: #61dafb;
}



